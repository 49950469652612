import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { Message, getLocale, useIntl } from '@myci/intl';
import { applySpec, compose } from 'ramda';
import { connect } from 'react-redux';
import { graphql } from 'gatsby';
import { navigate } from '@gatsbyjs/reach-router';
import { withReducers } from 'redux-syringe';
import { isNotNilNorEmpty } from '@myci/utils';
import { SelectedSubscriptionShape, Subscriptions, UserShape } from '@myci/domain-subscriptions';
import {
	getSubscriptionPlan,
	getUserType,
	reducer as registration,
	setSubscriptionPlan,
	setUserType,
} from '@myci/domain-registration';
import {
	AnchorLink,
	Box,
	Carousel,
	Grid,
	Icon,
	Link,
	Section,
	SectionHeading,
	Video,
} from '@myci/ui-components';
import { UserTypeSwitch } from '@myci/module-registration';
import { Button } from '@ci/atoms';
import { prepareStyle } from '@ci/styles';

import {
	getDoesFileExist,
	getLocalizedImageDataObject,
	getLocalizedUrl,
} from '../features/localizeUrl';
import m from '../messages';
import { Badge, Img, SectionMessage } from '../components';
import { moduleConfig } from '../config';
import PublicLayout from '../layouts/PublicLayout';

const stickyMenuButtonStyle = prepareStyle(utils => ({
	display: 'none',
	marginInlineEnd: utils.spacings.md,
	selectors: {
		[utils.breakpoints.sm]: {
			display: 'block',
		},
	},
}));

export const indexPageContentOnSticky = (
	<Button
		onClick={() => navigate('/registration')}
		size="small"
		customStyle={stickyMenuButtonStyle}
	>
		<Message {...m.getAnAccountLink} />
	</Button>
);

const Index = ({ data, selectedPlan, setSubscriptionPlan, userType, locale }) => {
	const {
		app,
		appStore,
		benefits,
		googlePlay,
		scoring,
		contracts,
		disputes,
		ciLogo,
		dashboard,
		worldMap,
		intro: {
			publicURL,
			internal: { mediaType },
		},
	} = data;

	const localizedIntroUrl = getLocalizedUrl(locale, publicURL);
	const introVideoUrl = getDoesFileExist(localizedIntroUrl) ? localizedIntroUrl : publicURL;

	useEffect(() => {
		if (isNotNilNorEmpty(selectedPlan)) {
			setSubscriptionPlan(null);
		}
	}, []);

	const intl = useIntl();

	const {
		settings: { mobileAppEnabled, enabledSubscriptionPlans },
	} = moduleConfig;

	const handleSubscriptionClick = plan => {
		setSubscriptionPlan(plan);
		navigate('registration');
	};

	return (
		<PublicLayout
			className="bg-white"
			contentOnSticky={indexPageContentOnSticky}
			header={
				<Section kind="header">
					<Grid container>
						<Grid row>
							<Grid col={{ xs: 12, md: 6, lg: 5 }} order={{ xs: 2, md: 1 }} mb={{ xs: 3, lg: 0 }}>
								<SectionMessage {...m.indexHeroSection} />
								<Button onClick={() => navigate('/registration')}>
									<Message {...m.getAnAccountLink} />
								</Button>
							</Grid>
							<Grid col={{ xs: 12, md: 6, lg: 7 }} order={{ xs: 1, md: 2 }}>
								<Video
									src={introVideoUrl}
									mediaType={mediaType}
									className="intro-video"
									autoPlay
									muted
									loop
								/>
							</Grid>
							<Grid col={12} className="d-none d-md-block text-center" order={{ md: 3 }}>
								<AnchorLink id="benefits">
									<Icon type="chevron-down" size={22} pr={0} />
								</AnchorLink>
							</Grid>
						</Grid>
					</Grid>
				</Section>
			}
		>
			<Section id="benefits" menuLabel={<Message {...m.indexBenefitsTitle} />}>
				<Grid container>
					<Grid row alignItems="center">
						<Grid col={{ xs: 10, md: 5, lg: 4 }}>
							<Img
								data={getLocalizedImageDataObject(locale, benefits)}
								className="img-fluid ml-xl-n4"
								alt="benefits"
							/>
						</Grid>
						<Grid col={{ xs: 12, md: 6 }} offset={{ md: 1, lg: 2 }}>
							<SectionMessage {...m.indexBenefitsSection} ulProps={{ color: 'teal' }} />
						</Grid>
					</Grid>
				</Grid>
			</Section>
			<Section id="pricing" menuLabel={<Message {...m.indexPricingTitle} />}>
				<Grid container>
					<Grid row>
						<Grid col={12} mb={{ xs: 3, md: 4 }}>
							<SectionHeading as="h2" mb={{ xs: 3, md: 4 }} align={{ md: 'center' }}>
								<Message {...m.indexPricingTitleSection} />
							</SectionHeading>
							<Grid alignItems="baseline" justifyContent="center" flexDirection={['column', 'row']}>
								<Grid
									as="p"
									alignItems="baseline"
									mb={{ xs: 3, md: 0 }}
									mr={{ sm: 3, lg: 4 }}
									className="color-info"
								>
									<Badge number={1} text={<Message {...m.indexBadgeChooseYourSub} />} />
								</Grid>
								<Grid
									as="p"
									alignItems="baseline"
									mb={{ xs: 3, md: 0 }}
									mr={{ sm: 3, lg: 4 }}
									className="color-info"
								>
									<Badge number={2} text={<Message {...m.indexBadgeFillInRegistration} />} />
								</Grid>
								<Grid as="p" alignItems="baseline" mb={{ xs: 3, md: 0 }} className="color-info">
									<Badge number={3} text={<SectionMessage {...m.indexBadgeAuthorization} />} />
								</Grid>
							</Grid>
						</Grid>
						<Grid col={12} mb={{ xs: 3, lg: 4 }} className="text-center">
							<UserTypeSwitch />
						</Grid>
						<Grid col={12}>
							<Subscriptions
								onSelect={handleSubscriptionClick}
								userType={userType}
								enabledSubscriptionPlans={
									enabledSubscriptionPlans ? enabledSubscriptionPlans[userType] : null
								}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Section>
			<Section className="section--sneakpeek">
				<Grid container>
					<Grid row>
						<Grid col={12}>
							<SectionHeading as="h2" align={{ md: 'center' }} mb={2}>
								<Message {...m.indexSneakpeakTitle} />
							</SectionHeading>
						</Grid>
					</Grid>
				</Grid>
				<Grid container fluid px={0}>
					<Grid row className="no-gutters">
						<Grid col={12}>
							<Carousel options={{ containerClass: 'swiper-container pt-3 pt-md-4 pb-4 pb-md-5' }}>
								<Img
									data={getLocalizedImageDataObject(locale, scoring)}
									className="carousel__img"
								/>
								<Img
									data={getLocalizedImageDataObject(locale, contracts)}
									className="carousel__img"
								/>
								<Img
									data={getLocalizedImageDataObject(locale, disputes)}
									className="carousel__img"
								/>
							</Carousel>
						</Grid>
					</Grid>
				</Grid>
				<Grid container>
					<Grid flex my={{ xs: 3, md: 4 }} justifyContent="center">
						<Box mx={2}>
							<SectionMessage liProps={{ mb: 2 }} {...m.indexSneakpeakChecklistStart} />
						</Box>
						<Box mx={2}>
							<SectionMessage liProps={{ mb: 2 }} {...m.indexSneakpeakChecklistEnd} />
						</Box>
					</Grid>
					<Box className="text-center">
						<Button onClick={() => navigate('/registration')}>
							<Message {...m.indexSneakpeakAction} />
						</Button>
					</Box>
				</Grid>
			</Section>
			<Section id="who-are-we" menuLabel={<Message {...m.indexWhoAreWeTitle} />}>
				<Grid container>
					<Grid row>
						<Grid col={12}>
							<SectionHeading as="h2" align={{ md: 'center' }} mb={2}>
								<Message {...m.indexWhoAreWeTitle} />
							</SectionHeading>
						</Grid>
					</Grid>
					<Grid row my={4} justifyContent="center" mt={4}>
						<Grid col={{ xs: 12, md: 6, lg: 4 }} offset={{ lg: 1 }} order={{ xs: 2, md: 1 }}>
							<SectionMessage h3Props={{ mb: 2 }} {...m.indexWhoAreWeSection_aboutCreditInfo} />
						</Grid>
						<Grid
							col={{ xs: 12, md: 6, lg: 4 }}
							offset={{ lg: 1 }}
							order={{ xs: 1, md: 2 }}
							justifyContent="center"
							alignItems="center"
						>
							<Img data={ciLogo} height={100} alt={intl.formatMessage(m.ciLogoAltText)} />
						</Grid>
					</Grid>
					<Grid row my={4} justifyContent="center">
						<Grid
							col={{ xs: 12, md: 6, lg: 4 }}
							offset={{ lg: 1 }}
							justifyContent="center"
							alignItems="center"
						>
							<Img data={dashboard} alt={intl.formatMessage(m.dashboardAltText)} />
						</Grid>
						<Grid col={{ xs: 12, md: 6, lg: 4 }} offset={{ lg: 1 }}>
							<SectionMessage
								h3Props={{ mb: 2 }}
								{...m.indexWhoAreWeSection_aboutLocalCreditBureau}
							/>
						</Grid>
					</Grid>
					<Grid row my={4} justifyContent="center">
						<Grid col={{ xs: 12, md: 6, lg: 4 }} offset={{ lg: 1 }} order={{ xs: 2, md: 1 }}>
							<SectionMessage h3Props={{ mb: 2 }} {...m.indexWhoAreWeSection_ourDigitalFootprint} />
						</Grid>
						<Grid
							col={{ xs: 12, md: 6, lg: 4 }}
							offset={{ lg: 1 }}
							order={{ xs: 1, md: 2 }}
							justifyContent="center"
							alignItems="center"
						>
							<Img data={worldMap} alt={intl.formatMessage(m.worldMapAltText)} />
						</Grid>
					</Grid>
				</Grid>
			</Section>
			{mobileAppEnabled && (
				<Section id="myci-app" menuLabel={<Message {...m.indexMyCiAppTitle} />}>
					<Grid container>
						<Grid row>
							<Grid col={{ xs: 12, md: 6, lg: 5 }} pt={{ xs: 3, lg: 3, xl: 4 }} offset={{ lg: 1 }}>
								<SectionMessage
									{...m.indexFooterSection}
									pProps={{ color: 'white' }}
									h2Props={{ color: 'white' }}
								/>
								<Grid flex>
									<Link href="#" mr={[3, 3, 4]}>
										<Img data={appStore} alt="appstore" />
									</Link>
									<Link href="#">
										<Img data={googlePlay} alt="google play" />
									</Link>
								</Grid>
							</Grid>
							<Grid col={{ xs: 12, md: 6, lg: 6 }} mt={{ xs: 4, md: 0 }}>
								<Img
									data={getLocalizedImageDataObject(locale, app)}
									className="app-img img-fluid"
									alt={intl.formatMessage(m.mobileAppImageAlt)}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Section>
			)}
		</PublicLayout>
	);
};

export const query = graphql`
	query {
		app: file(relativePath: { eq: "images/app.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		appStore: file(relativePath: { eq: "images/appstore.svg" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		benefits: file(relativePath: { eq: "images/benefits.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		googlePlay: file(relativePath: { eq: "images/google-play.svg" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		intro: file(relativePath: { eq: "videos/intro.mp4" }) {
			publicURL
			internal {
				mediaType
			}
		}
		scoring: file(relativePath: { eq: "images/dashboard-app-preview_scoring.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		contracts: file(relativePath: { eq: "images/dashboard-app-preview_contracts.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		disputes: file(relativePath: { eq: "images/dashboard-app-preview_disputes.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		ciLogo: file(relativePath: { eq: "images/ci.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		worldMap: file(relativePath: { eq: "images/world-map.png" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
		dashboard: file(relativePath: { eq: "images/dashboard.svg" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED)
			}
		}
	}
`;

Index.propTypes = {
	locale: PropTypes.string,
	selectedPlan: SelectedSubscriptionShape,
	setSubscriptionPlan: PropTypes.func,
	setUserType: PropTypes.func,
	userType: UserShape,
};

export default compose(
	withReducers({ registration }, { isGlobal: true, isPersistent: true }),
	connect(
		applySpec({
			selectedPlan: getSubscriptionPlan,
			userType: getUserType,
			locale: getLocale,
		}),
		{
			setUserType,
			setSubscriptionPlan,
		}
	),
	memo
)(Index);
